import {SafeAny} from '@/types';
import {http} from '@/utils';

export interface TabType {
  type: string;
  typeName: string;
}

export const getTabs = () => {
  return http.post<null, TabType[]>('app/user/balance/types');
};

export const getList = (data: {
  pageNo: number | 1;
  pageSize?: number | 10;
  changeDesc: string;
  yearMonth: string;
}) => {
  return http.post<null, SafeAny[]>('app/user/balance', data);
};
