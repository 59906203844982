import React from 'react';
import {View} from 'react-native';
const iconSize = 8;
export default () => (
  <View style={[{width: iconSize, height: iconSize}]}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none">
      <path
        d="M1.00033 3.66667L2.92625 5.74074L6.66699 2"
        stroke="white"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </View>
);
