/* eslint-disable @typescript-eslint/no-unused-vars */
import {BasicObject, SafeAny} from '@types';
import DetailNavTitle from '@/components/business/detail-nav-title';
import {View} from 'react-native';
import React, {useEffect, useState, useRef} from 'react';
import theme from '@style';
import config from '@utils/env.config';
import globalStore from '@/services/global.state';
import LazyImage from '@basicComponents/image';
import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import {goTo, goBack} from '@/utils';
import Spin from '@basicComponents/spin';
import {useTranslation} from 'react-i18next';
import WebViewInner, {WebViewInnerRef} from './webview-inner';

type GameType = 'kerala' | 'digit' | 'color' | 'dice' | 'matka';

const GameWebView = (props: SafeAny) => {
  const {i18n} = useTranslation();
  const gameMap: Record<
    GameType,
    {
      title: string;
      path: string;
      uni?: boolean;
    }
  > = {
    kerala: {
      title: i18n.t('home.kerala.title'),
      path: '/kerala',
    },
    digit: {
      title: i18n.t('home.digit.title'),
      path: '/digit',
    },
    color: {
      title: i18n.t('home.color.title'),
      path: '/color',
    },
    dice: {
      title: i18n.t('home.dice.title'),
      path: '/dice',
    },
    matka: {
      title: i18n.t('home.matka.title'),
      path: '/satta',
    },
  };
  const {route} = props;
  const type: GameType = route.params.type;
  const params = route.params.params;
  const gameOption = gameMap[type || 'color'];
  const [title, setTitle] = useState<string>(gameOption.title);

  const urlPrefix = `${gameOption.uni ? config.uniUrl : config.reactH5Url}${
    gameOption.path
  }${params ? `?${params}` : ''}`;
  const [ruleUrl, setRuleUrl] = useState<string>();
  const [pageLoading, setPageLoading] = React.useState(true);
  const [innerGoBack, setInnerGoBack] = useState(false);
  const [navWebviewCanGoback, setNavWebviewCanGoback] =
    useState<boolean>(false);
  const ref = useRef<WebViewInnerRef>(null);
  const handleMessage = (data: string | BasicObject) => {
    if (data === 'pageLoading:show') {
      setPageLoading(true);
      return;
    }
    if (data === 'pageLoading:hide') {
      setPageLoading(false);
      return;
    }
    if (data.startsWith?.('title:')) {
      // 表示更改标题
      setTitle(data.substring('title:'.length));
      return;
    }
    if (data.startsWith?.('rule:')) {
      setRuleUrl(data.substring('rule:'.length));
      return;
    }
  };
  const handleGoBack = (path?: string) => {
    ref.current?.goBack();
  };
  useEffect(() => {
    if (globalStore.token) {
      globalStore.updateAmount.next();
    }
  }, []);
  return (
    <View style={[theme.fill.fill, theme.flex.col]}>
      <DetailNavTitle
        title={title}
        hideServer={true}
        onBack={() => handleGoBack()}
      />
      <Spin loading={pageLoading} style={[theme.flex.flex1]}>
        <WebViewInner
          title={title}
          ref={ref}
          urlPrefix={urlPrefix}
          onMessage={handleMessage}
          onLoadEnd={() => {
            setPageLoading(false);
          }}
        />
      </Spin>
    </View>
  );
};

export default GameWebView;
