import React from 'react';
import {Dialog, LinearProgress} from '@rneui/themed';
import Text from '@basicComponents/text';
import theme from '@/style';
import {View} from 'react-native';
import {NavigatorScreenProps} from '@/types';
import {useTranslation} from 'react-i18next';
/** 这是一个加载页 */

export interface SplashProps {
  available?: number;
}

const Splash = (props: Partial<NavigatorScreenProps> & SplashProps) => {
  const {available = 0} = props;
  const [loading, setLoading] = React.useState(true);
  const {i18n} = useTranslation();
  React.useEffect(() => {
    return () => {
      setLoading(false);
    };
  }, []);
  return (
    <Dialog
      isVisible={loading}
      overlayStyle={[theme.fill.fill, theme.padding.xxl]}>
      <View style={[theme.fill.fill, theme.flex.col]}>
        <View style={[theme.fill.fill, theme.padding.xxl, theme.padding.btml]}>
          <View style={[theme.flex.flex1, theme.flex.center]}>
            <Dialog.Loading />
            <Text main textAlign="center" size="large">
              Loading...
            </Text>
          </View>
          <Text
            style={[theme.margin.btml]}
            main
            textAlign="center"
            size="large">
            {available === 0 && i18n.t('splash.tip.checkingVersion')}
            {available === 1 && i18n.t('splash.tip.downloading')}
            {available === 2 && i18n.t('splash.tip.checkingResources')}
          </Text>
          <LinearProgress />
        </View>
      </View>
    </Dialog>
  );
};

export default Splash;
