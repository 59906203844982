import LazyImage from '@basicComponents/image';
import theme from '@/style';
import React from 'react';
import {View} from 'react-native';
import {walletIcon} from './wallet.variable';
import Text from '@basicComponents/text';
import {toPriceStr} from '@/utils';
import globalStore from '@/services/global.state';
import Button from '@basicComponents/button';
import LinearGradient from '@basicComponents/linear-gradient';
import {useInnerStyle} from './wallet.hooks';
import {Shadow} from 'react-native-shadow-2';
import {useTranslation} from 'react-i18next';

export interface WalletTotalWalletProps {
  login: boolean;
  amount: number;
  onRecharge?: () => void;
}

const WalletTotalWallet: React.FC<WalletTotalWalletProps> = ({
  login,
  amount,
  onRecharge,
}) => {
  const {i18n} = useTranslation();
  const {totalWalletStyle, rechargeButtonShadow} = useInnerStyle();

  return (
    <View
      style={[
        theme.flex.row,
        theme.flex.between,
        theme.padding.lrl,
        theme.margin.btmm,
      ]}>
      <View style={[theme.flex.row]}>
        <View style={[theme.margin.rights]}>
          <LazyImage
            occupancy="#0000"
            imageUrl={walletIcon}
            width={40}
            height={40}
          />
        </View>
        <View style={[theme.flex.col]}>
          <Text fontSize={theme.fontSize.s} color={theme.basicColor.white}>
            {i18n.t('wallet.total')}
          </Text>
          <View style={[theme.flex.row, theme.flex.alignEnd]}>
            {login && (
              <Text
                fontSize={theme.fontSize.l}
                fontFamily="fontDin"
                blod
                color={theme.basicColor.white}>
                {globalStore.currency}
              </Text>
            )}
            <Text
              fontSize={theme.fontSize.xl}
              color={theme.basicColor.white}
              blod
              fontFamily="fontDin">
              {login
                ? toPriceStr(amount, {
                    fixed: 2,
                    showCurrency: false,
                    thousands: true,
                  })
                : '******'}
            </Text>
          </View>
        </View>
      </View>
      <Button
        buttonStyle={[
          totalWalletStyle.rechargeButton,
          theme.overflow.hidden,
          // eslint-disable-next-line react-native/no-inline-styles
          {height: 29},
        ]}
        radius={23}
        onPress={onRecharge}>
        <Shadow {...rechargeButtonShadow}>
          <LinearGradient
            // eslint-disable-next-line react-native/no-inline-styles
            style={[theme.fill.fill, theme.flex.center, {height: 34}]}
            colors={theme.basicColor.rechargeButtonLinearGradient}
            start={{x: 0, y: 0}}
            end={{x: 1, y: 0}}>
            <Text
              fontSize={theme.fontSize.s}
              blod
              color={theme.basicColor.white}>
              {i18n.t('wallet.recharge')}
            </Text>
          </LinearGradient>
        </Shadow>
      </Button>
    </View>
  );
};

export default WalletTotalWallet;
