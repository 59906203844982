import {BasicObject, SafeAny} from '@types';
import config from '@/utils/env.config';
import theme from '@style';
import {View} from 'react-native';
import React, {useEffect, useState} from 'react';
import globalStore from '@/services/global.state';
import DetailNavTitle from '@businessComponents/detail-nav-title';
import Spin from '@basicComponents/spin';
import {goTo} from '@utils';
import {useWebView} from '../hooks/webview.hooks';

const DetailWebView = (props: SafeAny) => {
  const {route} = props;
  const {
    path,
    originUrl,
    isReactH5 = '1',
    header,
    headerTitle,
    hideAmount,
    hideServer = true,
    serverRight = false,
  } = route.params;
  const urlPrefix = `${
    isReactH5 === '1' ? config.reactH5Url : config.uniUrl
  }${path}`;
  const [title, setTitle] = useState<string>(headerTitle);
  const [pageLoading, setPageLoading] = React.useState(true);
  const handleMessage = (data: string | BasicObject) => {
    if (data === 'pageLoading:show') {
      setPageLoading(true);
      return;
    }
    if (data === 'pageLoading:hide') {
      setPageLoading(false);
      return;
    }
    if (data.startsWith?.('title:')) {
      // 表示更改标题
      setTitle(data.substring('title:'.length));
      return;
    }
    if (data.startsWith?.('scratch:')) {
      goTo('Scratch', {
        path: data.substring('scratch:'.length),
      });
    }
  };
  useEffect(() => {
    // 令webview不因为未登录导致强制跳转登录（不通过配置backPage）
    if (globalStore.token) {
      globalStore.updateAmount.next();
    }
  }, []);
  useEffect(() => {
    setTitle(headerTitle);
  }, [headerTitle]);

  // const handleGoBack = () => {
  //   ref.current?.goBack();
  // };
  const {render, goBack} = useWebView({
    originUrl,
    urlPrefix,
    onMessage: handleMessage,
    onLoadEnd: () => {
      setPageLoading(false);
    },
  });

  return (
    <View style={[theme.fill.fill, theme.flex.col]}>
      {header && (
        <DetailNavTitle
          title={title}
          hideServer={hideServer}
          serverRight={serverRight}
          hideAmount={hideAmount || (!serverRight && !!globalStore.token)}
          onBack={() => goBack()}
        />
      )}
      <Spin loading={pageLoading} style={[theme.flex.flex1]}>
        {render}
      </Spin>
    </View>
  );
};

export default DetailWebView;
