import Refresh from '@/components/basic/error-pages/refresh';
import DetailNavTitle from '@/components/business/detail-nav-title';
import globalStore from '@/services/global.state';
import theme from '@/style';
import {SafeAny} from '@/types';
import {useResponsiveDimensions} from '@/utils';
import React from 'react';
import {KeyboardAvoidingView} from 'react-native';
import WebView from 'react-native-webview';
import {takeUntil} from 'rxjs';
import {bankPageTemplate} from './bank-page.template';

const ReuseWebView = () => {
  const ref = React.useRef<SafeAny>(null);
  let url = React.useRef('');
  const [finallyUrl, setFinallyUrl] = React.useState('');
  const {width} = useResponsiveDimensions();
  const [left, setLeft] = React.useState(width);

  const [title, setTitle] = React.useState('');
  const [rightNode, setRightNode] = React.useState<React.JSX.Element | null>(
    null,
  );
  const [hadCreate, setHadCreate] = React.useState(false);
  React.useEffect(() => {
    const createSub = globalStore.setReuseWebViewSub
      .pipe(takeUntil(globalStore.appDistory))
      .subscribe(res => {
        setHadCreate(true);
        setTitle(res.title);
        setRightNode(res.rightNode || null);
        clearHistory();
        url.current = res.url;
        console.log('设置url', url.current);
        setFinallyUrl(url.current);
      });
    const updateSub = globalStore.updateReuseWebViewSub
      .pipe(takeUntil(globalStore.appDistory))
      .subscribe(res => {
        typeof res.title === 'string' &&
          res.title !== title &&
          setTitle(res.title);
        res.rightNode &&
          res.rightNode !== rightNode &&
          setRightNode(res.rightNode || null);
        if (typeof res.url === 'string' && res.url !== url.current) {
          if (res.clear) {
            clearHistory();
          }
          url.current = res.url;
          console.log('更新url', url.current);
          setFinallyUrl(url.current);
        }
      });
    const triggleSub = globalStore.triggleReuseWebViewCbSub
      .pipe(takeUntil(globalStore.appDistory))
      .subscribe(bool => {
        bool ? setLeft(0) : setLeft(width);
      });
    return () => {
      createSub.unsubscribe();
      updateSub.unsubscribe();
      triggleSub.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearHistory = () => {
    if (!globalStore.isWeb) {
      (ref.current as WebView)?.clearHistory?.();
    }
  };

  React.useEffect(() => {
    console.log('url重置', finallyUrl);
    finallyUrl ? setLeft(0) : setLeft(width);
  }, [finallyUrl, width]);

  return (
    <KeyboardAvoidingView
      style={[
        theme.fill.fill,
        theme.position.abs,
        {
          left: left,
        },
      ]}>
      <DetailNavTitle
        title={title}
        hideServer
        onBack={() => {
          globalStore.reuseWebViewCbSub.next({type: 'onBack'});
        }}
        rightNode={rightNode}
      />
      {globalStore.isWeb
        ? !!finallyUrl && (
            <iframe
              ref={ref}
              src={finallyUrl}
              height={'100%'}
              width={'100%'}
              // eslint-disable-next-line react-native/no-inline-styles
              style={{
                borderWidth: 0,
                flex: 1,
                display: 'flex',
              }}
              onError={() => {
                globalStore.reuseWebViewCbSub.next({
                  type: 'onLoadEnd',
                  value: 'failed',
                });
              }}
              onLoad={() => {
                globalStore.reuseWebViewCbSub.next({
                  type: 'onLoadEnd',
                  value: 'success',
                });
              }}
              allow="autoplay; clipboard-read; clipboard-write"
            />
          )
        : hadCreate && (
            <WebView
              ref={ref}
              source={finallyUrl ? {uri: finallyUrl} : {html: bankPageTemplate}}
              cacheEnabled={true}
              collapsable={false}
              cacheMode={'LOAD_NO_CACHE'}
              domStorageEnabled={true}
              style={[
                theme.flex.flex1,
                {
                  backgroundColor: theme.basicColor.transparent,
                },
              ]}
              renderError={() => (
                <Refresh
                  style={[theme.background.lightGrey]}
                  onTryAgain={() => {
                    ref.current?.reload();
                  }}
                />
              )}
              onLoadEnd={() => {
                globalStore.reuseWebViewCbSub.next({
                  type: 'onLoadEnd',
                  value: 'success',
                });
              }}
              onError={() => {
                globalStore.reuseWebViewCbSub.next({
                  type: 'onLoadEnd',
                  value: 'failed',
                });
              }}
              onMessage={e => {
                globalStore.reuseWebViewCbSub.next({
                  type: 'onMessage',
                  value: e,
                });
              }}
              onNavigationStateChange={e => {
                globalStore.reuseWebViewCbSub.next({
                  type: 'onNavigationStateChange',
                  value: e,
                });
              }}
            />
          )}
    </KeyboardAvoidingView>
  );
};

export default ReuseWebView;
